import React, { Fragment } from "react";
import {Button} from 'react-bootstrap';
import { useState } from "react";
import api from "../api/axiosConfig";
import OrganizationTable from "./OrganizationTable";

const organizationApi = "/PharmacyTPR";
export function SearchPharmacy({pharmacySearchTitleId , abbreviated, setForPharmacySelectionRefresh}) {
  const [searchType, setSearchType] = useState(0);
  const [response, setResponse] = useState([
    Array(0)
  ]);
  const [resultNumber, setResultNumber] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [pharmacyCpridText, setPharmacyCpridText] = useState("");
  const [loadingState, setLoadingState] = useState(false);

  function handleSearchSubmit(apiData, pharmacyCprid, searchString) {
    const searchResponse = [...response.slice(), apiData];
    setResponse(searchResponse);
    setResultNumber(response.length);
    setSearchString(searchString);
    setPharmacyCpridText(pharmacyCprid);
  }

  function handlePharmacyCprid(event) {
    setPharmacyCpridText(event.target.value);
  }
  function handleSearchString(event) {
    setSearchString(event.target.value);
  }
  function handleNextResult() {
    if (resultNumber + 1 == response.length) {
      return;
    }
    const nextResultNumber = resultNumber + 1;
    setResultNumber(nextResultNumber);
  }
  function handlePreviousResult() {
    if (resultNumber == 0) {
      return;
    }
    const nextResultNumber = resultNumber - 1;
    setResultNumber(nextResultNumber);
  }
  return (
    <Fragment>
      <h2 id={pharmacySearchTitleId}>Pharmacy Search</h2>
      <SearchTypeRadioButtons
        onRadioButtonClicked={(event) => setSearchType(event.target.value)}
      />
      <br />
      <PharmacySearchType
        handleSearchString={handleSearchString}
        handlePharmacyCprid={handlePharmacyCprid}
        searchType={searchType}
        handleSearchSubmit={handleSearchSubmit}
        searchString={searchString}
        pharmacyCpridText={pharmacyCpridText}
        setLoadingState={setLoadingState}
      />
      <br />
      <br />
      <OrganizationTable
        organizationResults={response[resultNumber]}
        handlePreviousResult={handlePreviousResult}
        handleNextResult={handleNextResult}
        responseTotal={response.length}
        resultNumber={resultNumber}
        loadingState={loadingState}
        abbreviated={abbreviated}
    setForPharmacySelectionRefresh={setForPharmacySelectionRefresh}
      />
    </Fragment>
  );
}
function PharmacySearchType({
  handleSearchString,
  handlePharmacyCprid,
  searchType,
  handleSearchSubmit,
  searchString,
  pharmacyCpridText,
  setLoadingState
}) {
  const typeCPRID = 1;
  const typeSearchString = 2;
  if (searchType == typeCPRID) {
    return (
      <SearchPharmacyByCprid
        pharmacyCpridText={pharmacyCpridText}
        onSubmit={handleSearchSubmit}
        handlePharmacyCprid={handlePharmacyCprid}
      setLoadingState={setLoadingState}
      />
    );
  }
  if (searchType == typeSearchString) {
    return (
      <SearchPharmacyBySearchString
        handleSearchString={handleSearchString}
        onSubmit={handleSearchSubmit}
        searchString={searchString}
        setLoadingState={setLoadingState}
      />
    );
  }
  return <label>Select search type</label>;
}
function SearchPharmacyByCprid({
  onSubmit,
  pharmacyCpridText,
  handlePharmacyCprid,
  setLoadingState
}) {
  function handleSubmit(e) {
    e.preventDefault();
    setLoadingState(true);

    const form = e.target;
    const formData = new FormData(form);

    const formJson = Object.fromEntries(formData.entries());
    console.log("search by cpird");
    if (formJson["pharmacyCpridText"] == "") {
      alert("Please enter a CPRID");
      setLoadingState(false);
      return;
    }
    getOrganizationsByCprid(formJson["pharmacyCpridText"]);
  }

  const getOrganizationsByCprid = async (cpridText) => {
    try {
      const apiResponse = await api.get(organizationApi + "/" + cpridText);
      onSubmit(apiResponse.data, cpridText);
      setLoadingState(false);
    } catch (err) {
      console.log(err);
      onSubmit("Error occurred", cpridText);
    }
  };

  return (
    <Fragment>
      <form method="post" onSubmit={handleSubmit}>
        <div className="cpirdSubmit">
          <label>
            Pharmacy CPRID:
          </label>
            <input
              id="pharmacyCprid"
              name="pharmacyCpridText"
              defaultValue={pharmacyCpridText}
              onChange={handlePharmacyCprid}
            />
          <Button variant="danger" type="submit">Search</Button>
        </div>
      </form>
    </Fragment>
  );
}
function SearchPharmacyBySearchString({
  onSubmit,
  searchString,
  handleSearchString,
  setLoadingState
}) {
  const searchstringApi = organizationApi + "/string/province";

  function handleSubmit(e) {
    setLoadingState(true);
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);

    const formJson = Object.fromEntries(formData.entries());
    console.log("search by string");
    if (formJson["pharmacySearchString"] == "") {
      alert("Please enter a value to search by");
      setLoadingState(false);
      return;
    }
    getOrganizations(formJson["pharmacySearchString"], formJson["province"]);
  }

  const getOrganizations = async (searchString, province) => {
    try {
      const apiResponse = await api.get(
        searchstringApi + "/" + searchString + "/" + province
      );
      onSubmit(apiResponse.data, "", searchString);
      setLoadingState(false);
    } catch (err) {
      console.log(err);
      onSubmit("Error occurred", "", searchString);
      setLoadingState(false);
    }
  };
  return (
    <Fragment>
      <form method="post" onSubmit={handleSubmit}>
        <div className="searchSubmit">
          <div className="combinationContainer">
            <label className="combinationLabel">Search terms:</label>{" "}
            <input
              className="combinationInput"
              name="pharmacySearchString"
              defaultValue={searchString}
              onChange={handleSearchString}
            />
          </div>
          <div className="combinationContainer">
            <label className="combinationLabel">Province:</label>
            <select id="provinceDropDown" name="province" className="combinationInput">
              <option value="AB">Alberta</option>
              <option value="BC">British Columbia</option>
              <option value="MB">Manitoba</option>
              <option value="NB">New Brunswick</option>
              <option value="NL">Newfoundland</option>
              <option value="NS">Nova Scotia</option>
              <option value="NT">Northwest Territories</option>
              <option value="NU">Nunavut</option>
              <option value="ON">Ontario</option>
              <option value="PE">Prince Edward Island</option>
              <option value="QC">Quebec</option>
              <option value="SK">Saskatchewan</option>
              <option value="YT">Yukon</option>
            </select>
          </div>
          <Button variant="primary" type="submit">Search</Button>
        </div>
      </form>
    </Fragment>
  );
}
function SearchTypeRadioButtons({ onRadioButtonClicked }) {
  const typeCPRID = 1;
  const typeSearchString = 2;
  return (
    <Fragment>
      <div className="radioButtons" onChange={onRadioButtonClicked}>
        <label>
          CPRID Search
          <input type="radio" value={typeCPRID} name="pharmacySearchType" />
        </label>
        <label>
          Search by String
          <input
            type="radio"
            value={typeSearchString}
            name="pharmacySearchType"
          />
        </label>
      </div>
    </Fragment>
  );
}
