import { Fragment, useState } from "react";
import api from "../api/axiosConfig";
import { Button } from "react-bootstrap";
import { MessageTypeMenu } from "./SendMessages";
import LoadingThreeDots from "./LoadingThreeDots";
import { useAuthContext } from "../context/AuthContext";
import PharmacySelectionDropDown from "./PharmacySelection";
import { toast } from "react-toastify";

const WorkflowSender = () => {
  const [tasks, setTasks] = useState([]);
  const [messageType, setMessageType] = useState("");
  const [pharmacySelectedCprid, setPharmacySelectedCprid] = useState("");
  const [forPharmacySelectionRefresh, setForPharmacySelectionRefresh] =
    useState(false);
  const [workflowStatus, setWorkflowStatus] = useState("");
  const [loadingState, setLoadingState] = useState(false);

  function handleAddTaskButtonClicked(e) {
    e.preventDefault();
    setTasks([...tasks, messageType]);
  }
  function handleClearButtonClicked(e) {
    e.preventDefault();
    setTasks([]);
  }
  const { user } = useAuthContext();
  const userData = {
    email: user.email,
    quebecContext: false,
    tasks: tasks,
  };
  const sendWorkflowEndpoint = "/send/workflow";
  const sendWorkflow = async (pharmacySelectedCprid) => {
    try {
      const apiResponse = await api.post(
        sendWorkflowEndpoint + "/" + pharmacySelectedCprid,
        userData
      );
      setWorkflowStatus(apiResponse.data);
      setLoadingState(false);
      toast.success("Message sent successfully!");
    } catch (err) {
      console.log(err);
      setLoadingState(false);
      toast.error("Could not send message");
    }
  };
  function handleSendButtonClicked(e) {
    setLoadingState(true);
    setWorkflowStatus("sending...");
    sendWorkflow(pharmacySelectedCprid.value.id);
  }
  return (
    <Fragment>
      <div className="sub-container">
        <h2>Send a workflow</h2>
        <p>Select multiple tasks to send in order</p>
    <div className="pharmacySelection">
        <PharmacySelectionDropDown
          setPharmacySelectedCprid={setPharmacySelectedCprid}
          forPharmacySelectionRefresh={forPharmacySelectionRefresh}
          setForPharmacySelectionRefresh={setForPharmacySelectionRefresh}
          pharmacySelectedCprid={pharmacySelectedCprid}
        />
    <br/>
        <MessageTypeMenu
          onMessageTypeMenuSelected={(event) =>
            setMessageType(event.target.value)
          }
        />
    </div>
        <div>Workflow Status: {workflowStatus}</div>
        {loadingState === true ? (
          <LoadingThreeDots loadingState={loadingState} />
        ) : (
          <div className="flex-between">
            <Button variant="primary" onClick={handleAddTaskButtonClicked}>
              Add Task
            </Button>
            {tasks.length !== 0 ? (
              <Fragment>
                <Button variant="secondary" onClick={handleClearButtonClicked}>
                  Clear
                </Button>
                <Button variant="danger" onClick={handleSendButtonClicked}>
                  Send
                </Button>
              </Fragment>
            ) : (
              ""
            )}
          </div>
        )}
        {tasks.map((task, key) => (
          <div>
            {key + 1}. {task}
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default WorkflowSender;
