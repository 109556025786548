import { useEffect } from "react";
import Select from "react-select";
import { getPharmaciesAsDestinations } from "../api/getPharmaciesAsDestinations";

const PharmacySelectionDropDown = ({
  setPharmacySelectedCprid,
  setForPharmacySelectionRefresh,
  pharmacySelectedCprid
}) => {
  useEffect(() => {
    setForPharmacySelectionRefresh(false);
  });
  const pharmacyOptions = getPharmaciesAsDestinations();
  const selectStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: "9999 !important" })
};
  return (
    <Select menuPortalTarget={document.querySelector('body')} styles={selectStyles}
    value={pharmacySelectedCprid}
      onChange={(e) => setPharmacySelectedCprid(e)}
      options={pharmacyOptions}
      formatOptionLabel={(pharmacy) => (
        <div className="pharmacyOption">
          <span>{pharmacy.label}</span>
          <span className="pharmacyOptionErxStatus">
            {pharmacy.servicesOffered == "eRx Active" ? (
              <img
                className="icon"
                src="https://www.prescribeit.ca/images/icons/prescribeIT/ic-prescriber-white.png?v=3"
              />
            ) : (
              ""
            )}
          </span>
        </div>
      )}
    />
  );
};
export default PharmacySelectionDropDown;
