import React, { Fragment } from "react";
import api from "../api/axiosConfig";
import "bootstrap/dist/css/bootstrap.min.css";
import {Button} from 'react-bootstrap';
import "../App.css";
import { ThreeDots } from "react-loader-spinner";
import {toast } from "react-toastify";
import "../css/viewer.css"

const OrganzationTable = ({
  organizationResults,
  handlePreviousResult,
  handleNextResult,
  responseTotal,
  resultNumber,
  loadingState,
  abbreviated,
  setForPharmacySelectionRefresh
}) => {
  return (
    <div className="textDisplay">
      <label>
        {resultNumber + 1} of {responseTotal} Results
      </label>
      <br />
      <div className="textNav">
        <Button variant="secondary" onClick={handlePreviousResult}>Previous</Button>
        <Button variant="secondary" onClick={handleNextResult}>Next</Button>
      </div>
      <br />
      <br />
      <br />
      <h4>
        Number of pharmacies found:{" "}
        {organizationResults.length == 0
          ? 0
          : organizationResults[organizationResults.length - 1][
              "totalNumberOfResults"
            ]}{" "}
      </h4>
      <div>Up to 100 results displayed below</div>
      <ResutlsTable
        organizationResults={organizationResults}
        loadingState={loadingState}
        abbreviated={abbreviated}
    setForPharmacySelectionRefresh={setForPharmacySelectionRefresh}
      />
      <br />
    </div>
  );
};
function ResutlsTable({ organizationResults, loadingState, abbreviated, setForPharmacySelectionRefresh }) {
  const organizationResultsOnly = organizationResults.filter(object => !object.totalNumberOfResults)
  if (abbreviated) {
    return (
      <AbbreviatedResultsTable
        organizationResults={organizationResultsOnly}
        loadingState={loadingState}
      setForPharmacySelectionRefresh={setForPharmacySelectionRefresh}
      />
    );
  } else if (!abbreviated) {
    return (
      <FullResultsTable
        organizationResults={organizationResultsOnly}
        loadingState={loadingState}
      />
    );
  }
}
function DisplayErxStatus({ servicesOffered }) {
  if (servicesOffered.length == 0) {
    return "Inactive";
  } else if (
    servicesOffered.length >= 1 &&
    servicesOffered[0].code.code == "prescribeit-rx"
  ) {
    if (servicesOffered[0].status.code == "active") {
      return (
        <img
          className="icon"
          src="https://www.prescribeit.ca/images/icons/prescribeIT/ic-prescriber-white.png?v=3"
        />
      );
    } else {
      return "Not Active";
    }
  } else if (
    servicesOffered.length == 2 &&
    servicesOffered[1].code.code == "prescribeit-rx"
  ) {
    if (servicesOffered[1].status.code == "active") {
      return (
        <img
          className="icon"
          src="https://www.prescribeit.ca/images/icons/prescribeIT/ic-prescriber-white.png?v=3"
        />
      );
    } else {
      return "Not Active";
    }
  }
  return "Inactive";
}
function DisplayCcStatus({ servicesOffered }) {
  if (servicesOffered.length == 0) {
    return "Inactive";
  } else if (
    servicesOffered.length >= 1 &&
    servicesOffered[0].code.code == "clinician-comm"
  ) {
    if (servicesOffered[0].status.displayEn == "Active") {
      return (
        <img
          className="icon"
          src="https://www.prescribeit.ca/images/icons/prescribeIT/ic-improved-communications-white.png?v=3"
        />
      );
    } else {
      return "Not Active";
    }
  } else if (
    servicesOffered.length == 2 &&
    servicesOffered[1].code.code == "clinician-comm"
  ) {
    if (servicesOffered[1].status.displayEn == "Active") {
      return (
        <img
          className="icon"
          src="https://www.prescribeit.ca/images/icons/prescribeIT/ic-improved-communications-white.png?v=3"
        />
      );
    } else {
      return "Not Active";
    }
  }
  return "Inactive";
}
async function addPharmacy(organization){
  const addPharmacyApi = "/addOrUpdatePharmacy"
    try {
      const apiResponse = await api.post(addPharmacyApi, organization);
      console.log(apiResponse)
      toast.success("Added pharmacy");
    } catch (err) {
      console.log(err);
      toast.error("Could not add pharmacy");
    }
}
function AbbreviatedResultsTable({ organizationResults, loadingState, setForPharmacySelectionRefresh }) {
  const organizationTable = organizationResults.map((organization, index) => (
    <tr key={index}>
      <td><button className="downloadJson" onClick={() => {
        addPharmacy(organization)
        setForPharmacySelectionRefresh(true)
      }
      }>ADD</button></td>
      <td>{organization.name}</td>
      <td>{organization.id}</td>
      <td>
        {organization["servicesOffered"] ? (
          <DisplayErxStatus servicesOffered={organization["servicesOffered"]} />
        ) : null}
      </td>
      <td>
        {organization["servicesOffered"] ? (
          <DisplayCcStatus servicesOffered={organization["servicesOffered"]} />
        ) : null}
      </td>
    </tr>
  ));
  if (loadingState) {
    return (
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#4fa94d"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    );
  } else
    return (
      <Fragment>
      <table className="organizationTable">
        <thead>
          <tr key="abbreviatedHeader">
            <th></th>
            <th>Name</th>
            <th>CPRID</th>
            <th>eRx Status</th>
            <th>CC Status</th>
          </tr>
        </thead>
        <tbody>{organizationTable}</tbody>
      </table>
      </Fragment>
    );
}

function DisplayAddress({ organizationAddress }) {
  return (
    <Fragment>
      <div>{organizationAddress.street}</div>
      <div>{organizationAddress.street2}</div>
      <div>
        {organizationAddress.city}, {organizationAddress.province.displayEn}
      </div>
      <div>{organizationAddress.postalCode}</div>
      <div>{organizationAddress.country}</div>
      <div>{organizationAddress.addressType.displayEn}</div>
    </Fragment>
  );
}
function DisplayContactList({ contactList }) {
  const contactTable = contactList.map((contact) => (
    <Fragment>
      <div>
        {contact.value} ({contact.type.displayEn}, {contact.use.displayEn})
      </div>
    </Fragment>
  ));

  return contactTable;
}

function downloadTextFile({ organization }) {
  const pharmacyJson = JSON.stringify(organization);
  const blob = new Blob([pharmacyJson], { type: "text/json" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = organization.name + "_" + organization.id + ".json";
  link.href = url;
  link.click();
}
function FullResultsTable({ organizationResults, loadingState }) {
  const organizationTable = organizationResults.map((organization, index) => (
    <Fragment>
      <tr key={index}>
        <td>
          <td>
            {organization.name}
    <br />
    <br />
            <button
              className="downloadJson"
              onClick={() => downloadTextFile({ organization })}
            >
              Download JSON
            </button>
          </td>
        </td>
        <td>{organization.id}</td>
        <td>
          {organization["servicesOffered"] ? (
            <DisplayErxStatus
              servicesOffered={organization["servicesOffered"]}
            />
          ) : null}
        </td>
        <td>
          {organization["servicesOffered"] ? (
            <DisplayCcStatus
              servicesOffered={organization["servicesOffered"]}
            />
          ) : null}
        </td>
        <td>
          {organization["address"] ? (
            <DisplayAddress organizationAddress={organization["address"]} />
          ) : null}
        </td>
        <td>
          {organization["contactList"] ? (
            <DisplayContactList contactList={organization["contactList"]} />
          ) : null}
        </td>
        <td>{organization.lastUpdated}</td>
      </tr>
    </Fragment>
  ));
  if (loadingState) {
    return (
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#4fa94d"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    );
  } else
    return (
      <table className="organizationTable">
        <tbody>
          <tr key="fullHeader">
            <th>Name</th>
            <th>CPRID</th>
            <th>eRx Status</th>
            <th>CC Status</th>
            <th>Address</th>
            <th>Contact List</th>
            <th>Last Updated</th>
          </tr>
          {organizationTable}
        </tbody>
      </table>
    );
}
export default OrganzationTable;
