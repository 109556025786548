import { useAuthContext } from '../context/AuthContext'
import './ProtectedPage.css'

export function ProtectedPage({ children }) {
  const { user } = useAuthContext()
  if (user == null) {
    return (
      <div className='notice'>
        <h2>
          {' '}
          You must <a href='/'>login</a> to use this application{' '}
        </h2>
      </div>
    )
  }
  return children
}
