import api from "./axiosConfig"
export const getPharmaciesAsDestinations = () => {

function geteRxStatus(servicesOffered) {
  if (servicesOffered.length == 0) {
    return "Inactive";
  } else if (
    servicesOffered.length >= 1 &&
    servicesOffered[0].code.code == "prescribeit-rx"
  ) {
    if (servicesOffered[0].status.code == "active") {
      return ("eRx Active"
      );
    } else {
      return "Inactive";
    }
  } else if (
    servicesOffered.length == 2 &&
    servicesOffered[1].code.code == "prescribeit-rx"
  ) {
    if (servicesOffered[1].status.code == "active") {
      return (
        "eRx Active"
      );
    } else {
      return "Inactive";
    }
  }
  return "Inactive";
}
const getPharmaciesApi = "/getAllPharmacies";
  var pharmacyDisplayOptions = Array(0)
    const getPharmacyOptions = async () =>
      api.get(getPharmaciesApi).then((response) => {
        response.data.forEach(object => {
          let eRxStatus = geteRxStatus(object["servicesOffered"])
            let pharmacy = {"label": object.name + " (" + object.id + ")"}
          pharmacy['value'] = object
          pharmacy['servicesOffered'] = eRxStatus
          pharmacyDisplayOptions.push(pharmacy)
        })
        })
        .then(() => {
          pharmacyDisplayOptions.sort(function(a,b) {
            if (a.label < b.label) {
              return -1
            }
            if (a.label > b.label){
              return 1
            }
            return 0;
          })
      });
  getPharmacyOptions()
  return pharmacyDisplayOptions;
}
