import { Fragment, useState } from "react";
import api from "../api/axiosConfig";
import InboxTable from "./InboxTable";

export function PollInbox() {
  const inboxApi = "/pollInbox";
  const [response, setResponse] = useState([Array(0)]);
  const [resultNumber, setResultNumber] = useState(0);
  const [loadingState, setLoadingState] = useState(false);
  const pollInbox = async () => {
    try {
      setLoadingState(true);
      console.log("pollInbox");
      const apiResponse = await api.get(inboxApi);
      const pollResponses = [...response.slice(), apiResponse.data];
      setResponse(pollResponses);
      setResultNumber(response.length);
      setLoadingState(false);
    } catch (err) {
      console.log(err);
      setLoadingState(false);
    }
  };

  return (
    <Fragment>
    <h2>Inbox</h2>
      <button onClick={() => pollInbox()}>Poll Inbox</button>
      <br />
      <br />
      <InboxTable
        inboxResults={response[resultNumber]}
        loadingState={loadingState}
      />
    </Fragment>
  );
}
