import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import { ThreeDots } from "react-loader-spinner";
import api from "../api/axiosConfig";
import {toast} from "react-toastify";

const InboxTable = ({
  inboxResults,
  loadingState,
}) => {
  return (
    <div className="textDisplay">
      <h3>Last 20 inbox entries displayed below</h3>
      <InboxResultsTable
        inboxResults={inboxResults}
        loadingState={loadingState}
      />
      <br />
    </div>
  );
async function sendErrorNotification({inbox}) {
  const sendErrorNotification = "/sendErrorNotification"
  const webErrorNotificationRequest = {
    sourceReference: inbox.clinic.id,
    destinationReference: inbox.pharmacy.id,
    originalMessageId: inbox.messageHeaderId
  }
    try {
      const apiResponse = await api.post(sendErrorNotification, webErrorNotificationRequest);
      console.log(apiResponse)
      toast.success("Error Notification sent");
    } catch (err) {
      console.log(err);
      toast.error("Could not send Error Notification");
    }

}
function downloadTextFile({ inbox }) {
  const blob = new Blob([inbox.xml], { type: "text/xml" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = inbox.bundleType + "_" + inbox.pharmacy.name + "_" + inbox.dateReceived + ".xml";
  link.href = url;
  link.click();
}
  function InboxResultsTable({ inboxResults, loadingState }) {
    const inboxTable = inboxResults.map((inbox, index) => (
      <tr key={index}>
        <td>{inbox.dateReceived}</td>
        <td>{inbox.pharmacy ? inbox.pharmacy.name : ""}</td>
        <td>
          {inbox.patient ? inbox.patient.lastName : ""}{inbox.patient ? inbox.patient.lastName ? ", " : "" : ""}{inbox.patient ? inbox.patient.firstName : ""}
        </td>
        <td>{inbox.bundleType}
      <br />
      <div className="inboxTableButtons">
            <button
              className="downloadJson"
              onClick={() => downloadTextFile({ inbox})}
            >
              Download XML
            </button>
            <button
              className="sendErrorNotification"
              onClick={() => sendErrorNotification({inbox})}
            >
             Send Error Notification 
            </button>
      </div>
      </td>
      </tr>
    ));
    if (loadingState) {
      return (
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#4fa94d"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      );
    } else
      return (
        <table className="organizationTable">
          <thead>
            <tr key="abbreviatedHeader">
              <th>Date Received</th>
              <th>Pharmacy</th>
              <th>Patient</th>
              <th>Bundle Type</th>
            </tr>
          </thead>
          <tbody>{inboxTable}</tbody>
        </table>
      );
  }
};
export default InboxTable;
