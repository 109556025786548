import { signOut } from 'firebase/auth'
import { auth } from '../firebase'
import { useNavigate } from 'react-router-dom'
export function Logout() {
  const navigate = useNavigate()
  const onLogout = (e) => {
    e.preventDefault()
    signOut(auth)
      .then(() => {
        console.log('sign out successful')
        navigate('/')
      })
      .catch((error) => {
        console.log(error)
      })
  }
  return (
    <button className='logout' onClick={onLogout}>
      Logout
    </button>
  )
}
