import './App.css'
import React, { Fragment } from 'react'
import { useState } from 'react'
import { SearchPharmacy } from './components/SearchPharmacy'
import { PollInbox } from './components/PollInbox'
import { SendMessages } from './components/SendMessages'
import './css/navButton.css'
import './css/button.css'
import { Logout } from './components/LogoutButton'
import EmrModal from "./components/EmrModal"

export function Emr() {
  const [showSend, setShowSend] = useState(false)
  const [showInbox, setShowInbox] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalContent, setModalContent] = useState({})
  const onSendMessagesClick = () => {
    setShowSend(true)
    setShowInbox(false)
    setShowSearch(false)
  }
  const onShowInboxClick = () => {
    setShowSend(false)
    setShowInbox(true)
    setShowSearch(false)
  }
  const onSearchClick = () => {
    setShowSend(false)
    setShowInbox(false)
    setShowSearch(true)
  }
  return (
    <Fragment>
      <div className='navBar'>
        <h1>Cooper Clinic EMR</h1>
        <button className='navItem' onClick={onSendMessagesClick}>
          Send Messages
        </button>
        <button className='navItem' onClick={onShowInboxClick}>
          Inbox
        </button>
        <button className='navItem' onClick={onSearchClick}>
          Search Pharmacy
        </button>
        <Logout />
      </div>
      <div
        style={{ display: showSend ? 'block' : 'none' }}
        className='mainView'
      >
        <SendMessages setShowModal={setShowModal} setModalContent={setModalContent}/>
      </div>
      <div
        style={{ display: showInbox ? 'block' : 'none' }}
        className='mainView'
      >
        <PollInbox />
      </div>
      <div
        style={{ display: showSearch ? 'block' : 'none' }}
        className='mainView'
      >
        <SearchPharmacy />
      </div>
    <EmrModal showModal={showModal} setShowModal={setShowModal} body={modalContent}/>
    </Fragment>
  )
}
