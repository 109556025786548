import React, { useState, Fragment } from 'react'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../firebase'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './SignIn.css'

export function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const onLogin = (e) => {
    e.preventDefault()
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user
        navigate('/Application')
        console.log(user['email'] + ' has successfully logged in')
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log(errorCode, errorMessage)
        if (error.code === 'auth/wrong-password') {
          toast.error('Please check the password')
        }
        if (error.code === 'auth/user-not-found') {
          toast.error('This user does not exist')
        }
        if (error.code === 'auth/missing-password') {
          toast.error('Please enter a password')
        }
        if (error.code === 'auth/invalid-email') {
          toast.error('Please enter a valid email address')
        }
      })
  }
  return (
    <Fragment>
      <div className='root'>
        <div className='login'>
          <div className='title'>
            <img alt='Emr Icon' src='../favicon.ico' />
            <h1>Cooper Clinic EMR</h1>
          </div>
          <form>
            <label htmlFor='email'>Email Address</label>
            <input
              id='email'
              name='email'
              type='email'
              required
              placeholder='example@email.com'
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor='password'>Password</label>
            <input
              id='password'
              name='password'
              type='password'
              required
              placeholder='password'
              onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={onLogin}>Login</button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  )
}
