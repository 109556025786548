import { React, Fragment, useState } from "react";
import api from "../api/axiosConfig";
import LoadingThreeDots from "./LoadingThreeDots";
import { SearchPharmacy } from "./SearchPharmacy";
import "./SendMessage.css";
import "react-select-search/style.css";
import { useAuthContext } from "../context/AuthContext";
import PharmacySelectionDropDown from "./PharmacySelection";
import { Form, Button} from "react-bootstrap";
import { toast } from "react-toastify";
import SentMessages from "./SentMessages";
import WorkflowSender from "./WorkflowSender";

export function SendMessages({ showModal, setShowModal, setModalContent }) {
  const [response, setResponse] = useState([]);
  const [messageTypesSent, setMessagesTypeSent] = useState([]);
  const [resultNumber, setResultNumber] = useState(0);
  const [messageType, setMessageType] = useState("");
  const [loadingState, setLoadingState] = useState(false);
  const [forPharmacySelectionRefresh, setForPharmacySelectionRefresh] =
    useState(false);
  const [pharmacySelectedCprid, setPharmacySelectedCprid] = useState("");
  const [pharmacyRecipients, setPharmacyRecipients] = useState("");
  const { user } = useAuthContext();
  var userName = "";

  if (user.email === "jharris@cooperclinic.ca") {
    userName = "Joan Harris";
  }
  if (user.email === "ddraper@cooperclinic.ca") {
    userName = "Don Draper";
  }

  function handleSendMessage(apiData, messageType) {
    const sendResponse = [...response.slice(), apiData];
    setResponse(sendResponse);
    setResultNumber(response.length);
    const messagesType = [...messageTypesSent.slice(), messageType];
    setMessagesTypeSent(messagesType);
  }

  function handleNextResult() {
    if (resultNumber + 1 == response.length) {
      return;
    }
    const nextResultNumber = resultNumber + 1;
    setResultNumber(nextResultNumber);
  }
  function handlePreviousResult() {
    if (resultNumber == 0) {
      return;
    }
    const nextResultNumber = resultNumber - 1;
    setResultNumber(nextResultNumber);
  }
  return (
    <Fragment>
      <div className="sendMessagesContainer">
        <div className="sendMessages">
          <h2>Send Messages as {userName}</h2>
          <div className="messageType">
            <MessageTypeMenu
              onMessageTypeMenuSelected={(event) => 
                setMessageType(event.target.value)
              }
            />
          </div>
          <br />
          <>
            <SendRequest
              onSubmit={handleSendMessage}
              messageType={messageType}
              setLoadingState={setLoadingState}
              loadingState={loadingState}
              forPharmacySelectionRefresh={forPharmacySelectionRefresh}
              setForPharmacySelectionRefresh={setForPharmacySelectionRefresh}
              setPharmacySelectedCprid={setPharmacySelectedCprid}
              pharmacySelectedCprid={pharmacySelectedCprid}
              pharmacyRecipients={pharmacyRecipients}
              setPharmacyRecipients={setPharmacyRecipients}
              setShowModal={setShowModal}
              showModal={showModal}
              setModalContent={setModalContent}
            />
            <br />
            <SentMessages
              response={response}
              messageTypesSent={messageTypesSent}
              pharmacyRecipients={pharmacyRecipients}
            />
            <br/>
            <WorkflowSender/>
          </>
        </div>
        <div className="searchPharmacy">
          <SearchPharmacy
            setForPharmacySelectionRefresh={setForPharmacySelectionRefresh}
            pharmacySearchTitleId="pharmacySearchTitle"
            abbreviated={true}
          />
        </div>
      </div>
    </Fragment>
  );
}

function SendRequest({
  onSubmit,
  messageType,
  setLoadingState,
  loadingState,
  forPharmacySelectionRefresh,
  setForPharmacySelectionRefresh,
  setPharmacySelectedCprid,
  pharmacySelectedCprid,
  pharmacyRecipients,
  setPharmacyRecipients,
  setModalContent,
  setShowModal,
}) {
  const { user } = useAuthContext();
  const userData = {
    email: user.email,
    quebecContext: false,
  };
  const sendE110Api = "/send/e110";
  const sendE120Api = "/send/e120";
  const sendE161Api = "/send/e161";
  const sendE162Api = "/send/e162";
  const sendE163Api = "/send/e163";
  const sendE164Api = "/send/e164";
  const sendE140Api = "/send/e140";
  const sendE180Api = "/send/e180";
  const sendE110E120Api = "/send/bundleE110E120";
  const send50EmrRequestApi = "/send/50EmrRequestTasks";
  function handleSubmit(e) {
    setLoadingState(true);
    e.preventDefault();

    console.log(messageType);
    if (messageType == "e110m") {
      sendE110Message(pharmacySelectedCprid.value.id);
      const recipients = [...pharmacyRecipients, pharmacySelectedCprid.label];
      setPharmacyRecipients(recipients);
    }
    if (messageType == "e120m") {
      sendE120Message(pharmacySelectedCprid.value.id);
      const recipients = [...pharmacyRecipients, pharmacySelectedCprid.label];
      setPharmacyRecipients(recipients);
    }
    if (messageType == "e161m") {
      sendE161Message(pharmacySelectedCprid.value.id);
      const recipients = [...pharmacyRecipients, pharmacySelectedCprid.label];
      setPharmacyRecipients(recipients);
    }
    if (messageType == "e162m") {
      sendE162Message(pharmacySelectedCprid.value.id);
      const recipients = [...pharmacyRecipients, pharmacySelectedCprid.label];
      setPharmacyRecipients(recipients);
    }
    if (messageType == "e163m") {
      sendE163Message(pharmacySelectedCprid.value.id);
      const recipients = [...pharmacyRecipients, pharmacySelectedCprid.label];
      setPharmacyRecipients(recipients);
    }
    if (messageType == "e164m") {
      sendE164Message(pharmacySelectedCprid.value.id);
      const recipients = [...pharmacyRecipients, pharmacySelectedCprid.label];
      setPharmacyRecipients(recipients);
    }
    if (messageType == "e140m") {
      sendE140Message();
      const recipients = [...pharmacyRecipients, pharmacySelectedCprid.label];
      setPharmacyRecipients(recipients);
    }
    if (messageType == "e180m") {
      sendE180Message();
      const recipients = [...pharmacyRecipients, ""];
      setPharmacyRecipients(recipients);
    }
    if (messageType == "e110e120") {
      sendE110E120Message(pharmacySelectedCprid.value.id);
      const recipients = [...pharmacyRecipients, pharmacySelectedCprid.label];
      setPharmacyRecipients(recipients);
    }
    if (messageType == "50EmrRequestTasks") {
      send50EmrRequestTasks(pharmacySelectedCprid.value.id);
      const recipients = [...pharmacyRecipients, pharmacySelectedCprid.label];
      setPharmacyRecipients(recipients);
    }
    if (messageType == "") {
      alert("Please select a message type");
      setLoadingState(false);
    }
  }

  const sendE110Message = async (cpridText) => {
    try {
      const apiResponse = await api.post(
        sendE110Api + "/" + cpridText,
        userData
      );
      onSubmit(apiResponse.data, messageType);
      setLoadingState(false);
      toast.success("Message sent successfully!");
    } catch (err) {
      console.log(err);
      setLoadingState(false);
      toast.error("Could not send message");
    }
  };

  const sendE120Message = async (cpridText) => {
    try {
      const apiResponse = await api.post(
        sendE120Api + "/" + cpridText,
        userData
      );
      onSubmit(apiResponse.data, messageType);
      setLoadingState(false);
      toast.success("Message sent successfully!");
    } catch (err) {
      console.log(err);
      setLoadingState(false);
      toast.error("Could not send message");
    }
  };
  const sendE161Message = async (cpridText) => {
    try {
      const apiResponse = await api.post(
        sendE161Api + "/" + cpridText,
        userData
      );
      onSubmit(apiResponse.data, messageType);
      setLoadingState(false);
      toast.success("Message sent successfully!");
    } catch (err) {
      console.log(err);
      setLoadingState(false);
      toast.error("Could not send message");
    }
  };
  const sendE162Message = async (cpridText) => {
    try {
      const apiResponse = await api.post(
        sendE162Api + "/" + cpridText,
        userData
      );
      onSubmit(apiResponse.data, messageType);
      setLoadingState(false);
      toast.success("Message sent successfully!");
    } catch (err) {
      console.log(err);
      setLoadingState(false);
      toast.error("Could not send message");
    }
  };
  const sendE163Message = async (cpridText) => {
    try {
      const apiResponse = await api.post(
        sendE163Api + "/" + cpridText,
        userData
      );
      onSubmit(apiResponse.data, messageType);
      setLoadingState(false);
      toast.success("Message sent successfully!");
    } catch (err) {
      console.log(err);
      setLoadingState(false);
      toast.error("Could not send message");
    }
  };
  const sendE164Message = async (cpridText) => {
    try {
      const apiResponse = await api.post(
        sendE164Api + "/" + cpridText,
        userData
      );
      onSubmit(apiResponse.data, messageType);
      setLoadingState(false);
      toast.success("Message sent successfully!");
    } catch (err) {
      console.log(err);
      setLoadingState(false);
      toast.error("Could not send message");
    }
  };
  const sendE140Message = async () => {
    try {
      const apiResponse = await api.post(sendE140Api, userData);
      onSubmit(apiResponse.data, messageType);
      setLoadingState(false);
      toast.success("Message sent successfully!");
    } catch (err) {
      console.log(err);
      setLoadingState(false);
      toast.error("Could not send message");
    }
  };
  const sendE180Message = async () => {
    try {
      const apiResponse = await api.post(sendE180Api, userData);
      onSubmit(apiResponse.data, messageType);
      setLoadingState(false);
      let responseMetaData = apiResponse.data.split("\n");
      setModalContent([
        "Message sent successfully",
        responseMetaData[0],
        responseMetaData[1],
        responseMetaData[2],
      ]);
      setShowModal(true);
    } catch (err) {
      console.log(err);
      setLoadingState(false);
      toast.error("Could not send message");
    }
  };
  const sendE110E120Message = async (cpridText) => {
    try {
      const apiResponse = await api.post(
        sendE110E120Api + "/" + cpridText,
        userData
      );
      onSubmit(apiResponse.data, "e110 + e120");
      setLoadingState(false);
      toast.success("Message sent successfully!");
    } catch (err) {
      console.log(err);
      setLoadingState(false);
      toast.error("Could not send message");
    }
  };
  const send50EmrRequestTasks = async (cpridText) => {
    try {
      const apiResponse = await api.post(
        send50EmrRequestApi + "/" + cpridText,
        userData
      );
      onSubmit(apiResponse.data, "50 EmrRequestTasks");
      setLoadingState(false);
      toast.success("Message sent successfully!");
    } catch (err) {
      console.log(err);
      setLoadingState(false);
      toast.error("Could not send message");
    }
  };
  return (
    <Fragment>
      <form method="post" onSubmit={handleSubmit}>
        <div className="cpirdSubmit">
          <PharmacySelection
            messageType={messageType}
            forPharmacySelectionRefresh={forPharmacySelectionRefresh}
            setForPharmacySelectionRefresh={setForPharmacySelectionRefresh}
            setPharmacySelectedCprid={setPharmacySelectedCprid}
            pharmacySelectedCprid={pharmacySelectedCprid}
          />
          {loadingState === true ? (
            <LoadingThreeDots loadingState={loadingState} />
          ) : (
            ""
          )}
          {loadingState === false ? <Button variant="danger" type="submit">Send</Button> : ""}
        </div>
      </form>
    </Fragment>
  );
}
function PharmacySelection({
  messageType,
  forPharmacySelectionRefresh,
  setForPharmacySelectionRefresh,
  setPharmacySelectedCprid,
  pharmacySelectedCprid,
}) {
  if (messageType === "e110m") {
    return (
      <Fragment>
        <label>Send an e110 message to a pharmacy</label>
        <div className="pharmacySelection">
          <PharmacySelectionDropDown
            setPharmacySelectedCprid={setPharmacySelectedCprid}
            forPharmacySelectionRefresh={forPharmacySelectionRefresh}
            setForPharmacySelectionRefresh={setForPharmacySelectionRefresh}
            pharmacySelectedCprid={pharmacySelectedCprid}
            className="custom-select-search-container"
          />
        </div>
      </Fragment>
    );
  }
  if (messageType === "e120m") {
    return (
      <Fragment>
        <label>
          Send e120 to a pharmacy based on last EmrRequest (e110 or e120) sent
        </label>
        <div className="pharmacySelection">
          <PharmacySelectionDropDown
            setPharmacySelectedCprid={setPharmacySelectedCprid}
            forPharmacySelectionRefresh={forPharmacySelectionRefresh}
            setForPharmacySelectionRefresh={setForPharmacySelectionRefresh}
            className="custom-select-search-container"
          />
        </div>
      </Fragment>
    );
  }
  if (messageType.includes('e16')) {
    return (
      <Fragment>
        <label>
          Send sample renewal response to a pharmacy
        </label>
        <div className="pharmacySelection">
          <PharmacySelectionDropDown
            setPharmacySelectedCprid={setPharmacySelectedCprid}
            forPharmacySelectionRefresh={forPharmacySelectionRefresh}
            setForPharmacySelectionRefresh={setForPharmacySelectionRefresh}
            className="custom-select-search-container"
          />
        </div>
      </Fragment>
    );
  }
  if (messageType === "e180m") {
    return (
      <Fragment>
        <label>
          Send an e180 request and obtain message key, last name and birth date
        </label>
      </Fragment>
    );
  }
  if (messageType === "e140m") {
    return (
      <Fragment>
        <label>
          Send an e140 request for the last EmrRequest (e110 or e120) sent
        </label>
      </Fragment>
    );
  }
  if (messageType === "e110e120") {
    return (
      <Fragment>
        <label>Send a bundle of e110 and e120 to a pharmacy</label>
        <div className="pharmacySelection">
          <PharmacySelectionDropDown
            setPharmacySelectedCprid={setPharmacySelectedCprid}
            forPharmacySelectionRefresh={forPharmacySelectionRefresh}
            setForPharmacySelectionRefresh={setForPharmacySelectionRefresh}
            className="custom-select-search-container"
          />
        </div>
      </Fragment>
    );
  }
  if (messageType === "50EmrRequestTasks") {
    return (
      <Fragment>
        <label>Send a bundle of e110 (25) and e120 (25) to a pharmacy</label>
        <div className="pharmacySelection">
          <PharmacySelectionDropDown
            setPharmacySelectedCprid={setPharmacySelectedCprid}
            forPharmacySelectionRefresh={forPharmacySelectionRefresh}
            setForPharmacySelectionRefresh={setForPharmacySelectionRefresh}
            className="custom-select-search-container"
          />
        </div>
      </Fragment>
    );
  }
}

export function MessageTypeMenu({ onMessageTypeMenuSelected }) {
  return (
    <Fragment>
      <div className="pharmacySelection">
        <Form.Select
          placeholder="Select message type..."
          id="messageTypeMenu"
          name="messageType"
          onChange={onMessageTypeMenuSelected}
        >
          <option value="">Select message type...</option>
          <option value="e110m">e110</option>
          <option value="e120m">e120</option>
          <option value="e180m">e180</option>
          <option value="e140m">e140</option>
          <option value="e110e120">e110 + e120</option>
          <option value="50EmrRequestTasks">50 EmrRequest Tasks</option>
          <option value="e161m">e161</option>
          <option value="e162m">e162</option>
          <option value="e163m">e163</option>
          <option value="e164m">e164</option>
        </Form.Select>
      </div>
    </Fragment>
  );
}
