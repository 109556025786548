import { ListGroup, ListGroupItem, Button } from "react-bootstrap";
import "../css/sentMessages.css";

const SentMessages = ({ response, messageTypesSent, pharmacyRecipients}) => {
  return (
    <SentMessageList response={response} messageTypesSent={messageTypesSent} pharmacyRecipients={pharmacyRecipients}/>
  )
}

const SentMessageList = ({response, messageTypesSent, pharmacyRecipients}) => {
  
  function downloadTextFile(text) {
    const blob = new Blob([text], { type: "text/xml" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "result.xml";
    link.href = url;
    link.click();
  }
  return (
    <div className="sentMessages">
    <ListGroup>
      {Object.entries(response)
        .reverse()
        .map(([key, sentMessage]) => {
          if (Number(key) === response.length - 1) {
            return (
              <ListGroupItem key={key} active>
                <div className="sentEntry">
                  <div>{messageTypesSent[key]} : {pharmacyRecipients[key]}</div>
                  <Button variant="success" onClick={() => downloadTextFile(sentMessage)}>Download</Button>
                </div>
              </ListGroupItem>
            );
          } else {
            return (
              <ListGroupItem key={key}>
                <div className="sentEntry">
                  <div>{messageTypesSent[key]} : {pharmacyRecipients[key]}</div>
                  <Button variant="outline-success" onClick={() => downloadTextFile(sentMessage)}>Download</Button>
                </div>
              </ListGroupItem>
            );
          }
        })}
    </ListGroup>
    </div>
  );
}



export default SentMessages;
