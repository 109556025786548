import { Modal, ModalHeader } from "react-bootstrap";
const EmrModal = ({ showModal, setShowModal, body }) => {
  function handleClose() {
    setShowModal(false);
  }
  let content = [];
  if (Object.keys(body).length > 0) content = Object.entries(body);
  return (
    <Modal show={showModal} onHide={handleClose}>
      <ModalHeader>
        <Modal.Title>EMR Information</Modal.Title>
      </ModalHeader>
      <Modal.Body>{content.map(([key, item]) => {
        return(
          <div key={key}>{item}</div>
        )
      })}</Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default EmrModal;
