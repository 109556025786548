import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyBuy4I73HF_dURmezVxIEf8IdsIvG-TiPA",
  authDomain: "next-js-firebase-demo-43992.firebaseapp.com",
  projectId: "next-js-firebase-demo-43992",
  storageBucket: "next-js-firebase-demo-43992.appspot.com",
  messagingSenderId: "173956365953",
  appId: "1:173956365953:web:795aa438eabf05326bd780",
  measurementId: "G-YG15FKXQDZ"
};


const app = initializeApp(firebaseConfig)

export const auth = getAuth(app)
export default app
