import "./App.css";
import React, { useEffect, Fragment } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import { Login } from "./components/SignIn";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Emr } from "./Emr";
import { ProtectedPage } from "./pages/ProtectedPage";
import { AuthContextProvider, useAuthContext } from "./context/AuthContext";
import {ToastContainer} from "react-toastify";

export default function Home() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <AuthContextProvider>
          <LoginPage />
        </AuthContextProvider>
      ),
    },
    {
      path: "/Application",
      element: <EmrPage />,
    },
  ]);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      var userName = "";
      if (user != null) {
        if (user.email == "jharris@cooperclinic.ca") {
          userName = "Joan Harris";
        }
        if (user.email == "ddraper@cooperclinic.ca") {
          userName = "Don Draper";
        }
      }

      document.title = "Cooper Clinic EMR";
      if (user) {
        const userEmail = user.email;
        console.log(userEmail + " auth state changed");
        document.title = "Cooper Clinic EMR - Logged in as " + userName;
      } else {
        console.log("user is logged out");
        document.title = "Cooper Clinic EMR - Not Logged In";
      }
    });
  }, []);
  return <RouterProvider router={router} />;
}
function LoginPage() {
  const { user } = useAuthContext();
  if (user) {
    return <EmrPage />;
  }
  return (
    <Fragment>
      <Login />
    </Fragment>
  );
}
function EmrPage() {
  return (
    <Fragment>
    <AuthContextProvider>
      <ProtectedPage>
        <Emr />
      </ProtectedPage>
    <ToastContainer/>
    </AuthContextProvider>
    </Fragment>
  );
}
